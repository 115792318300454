import React from 'react'
import Blog1 from '../../../public/home/blog1.webp'
import Blog2 from '../../../public/home/blog2.jpeg'
import Blog3 from '../../../public/home/blog3.webp'
import Image from "next/future/image"

const HomeBlogs = () => {
    return (
        <>
            <div className="blog-container py-md-3 px-md-5 py-5 px-2">
                <div className="row g-0">
                    <div className="col py-md-5">
                        <h2 className="blog-title text-center">
                            Get started with <span className="theme-text">KonfHub</span>
                        </h2>
                        <div className="blog-sub-title text-center">
                            Check out these blogs to understand how you can use KonfHub to make your events super successful
                        </div>
                    </div>
                </div>
                <div className="row g-0 py-3 py-md-0 pb-md-5 px-md-5 px-3 d-flex">
                    <div className="col-lg-4 col-12 d-flex">
                        <span>
                            <div onClick={() => window.open("https://blog.konfhub.com/make-online-events-more-engaging", "_blank")} className="blog-card pointer d-flex flex-grow-1 flex-column mx-4">
                                <Image src={Blog1} className="w-100 h-auto" alt="Make Online Events More Engaging" width={226} height={194}/>
                                <div className="d-flex flex-column flex-grow-1 p-4 justify-content-end">
                                    <div className="blog-title">
                                        Maximizing Online Event Engagement: From Gamification to Post-Event Interaction
                                    </div>
                                    <div classname="d-inline-block">
                                        <button className="btn btn-theme btn-blog mt-3">
                                            Read Post&nbsp;&nbsp;&nbsp;&nbsp;
                                            <i className="fas fa-square-up-right" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </span>
                    </div>
                    <div className="col-lg-8 col-md-9 mt-4 mt-lg-0 col-12 d-flex flex-grow-1">
                        <div className="d-flex flex-grow-1 flex-column">
                            <div className="blogs-container d-flex flex-grow-1 mb-4 row g-0">
                                <div className="col-md-6 col-12">
                                    <span>
                                        <div onClick={() => window.open("https://blog.konfhub.com/4-big-ideas-for-developer-engagement-6859d495b7ea", "_blank")} className="blog-card pointer d-flex flex-grow-1 flex-column mx-4 mt-4 mt-md-0">
                                            <Image src={Blog2} className="w-100 height" alt="4 Big Ideas for Developer Engagement" width={226} height={205}/>
                                            <div className="d-flex flex-column flex-grow-1 p-4 card-height">
                                                <div className="blog-title">
                                                    4 Big Ideas for Developer Engagement
                                                </div>
                                                <div classname="d-inline-block">
                                                    <button className="btn btn-theme btn-blog mt-3">
                                                        Read Post&nbsp;&nbsp;&nbsp;&nbsp;
                                                        <i className="fas fa-square-up-right" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </span>
                                </div>
                                <div className="col-md-6 col-12">
                                    <span>
                                        <div onClick={() => window.open("https://blog.konfhub.com/konfhub-referral-program", "_blank")} className="blog-card pointer d-flex flex-grow-1 flex-column mx-4 mt-4 mt-md-0">
                                            <Image src={Blog3} className="w-100 height " alt="Automated Referral Contests: A Sure-shot Way to Get Genuine Participation!" width={226} height={205}/>
                                            <div className="d-flex flex-column flex-grow-1 p-4 card-height">
                                                <div className="blog-title">
                                                    Boost Ticket Sales with Automated Referral Contests
                                                </div>
                                                <div classname="d-inline-block">
                                                    <button className="btn btn-theme btn-blog mt-3">
                                                        Read Post&nbsp;&nbsp;&nbsp;&nbsp;
                                                        <i className="fas fa-square-up-right" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </span>
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="blog-card w-100 mx-4 p-4">
                                    <div className="blog-view-title">
                                        Check out our other exciting blogs!
                                    </div>
                                    <div className="blog-view-sub-title d-flex mt-3 flex-wrap flex-md-nowrap">
                                        <div className="d-flex align-items-center">
                                            Inviting you to visit our blog page to read more such interesting blogs.
                                        </div>
                                        <div className="d-flex flex-grow-1 justify-content-md-end align-items-center">
                                            <a href="https://blog.konfhub.com/" target="_blank" rel="noreferrer">
                                                <button className="btn btn-header mt-3 mt-md-0">
                                                    Explore More&nbsp;&nbsp;&nbsp;&nbsp;
                                                    <i className="fas fa-chevron-circle-right arrow" />
                                                </button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <style>{`
        .blog-container {
            background: #f6f9fc;
            font-family: 'Nunito';
        }

        .blog-container .blog-title {
            color: #572148;
            font-weight: 700;
            font-size: 50px;
        }

        .height{
            height: 205px !important;
        }
        .blog-container .blog-title .theme-text {
            color: #FB5850;
        }

        .blog-container .blog-sub-title {
            font-family: 'Nunito';
            font-weight: 400;
            font-size: 22px;
            color: #572148;
        }

        .blog-container .blog-card {
            background: #FFFFFF;
            box-shadow: 3px 3px 30px rgba(0, 0, 0, 0.2);
            border-radius: 6px;
            overflow: hidden;
        }

        .blog-container .blog-card .blog-title {
            font-weight: 700;
            font-size: 22px;
            line-height: 31px;
            color: #572148;
        }

        .blog-container .blog-card .blog-view-title {
            font-style: normal;
            font-weight: 700;
            font-size: 32px;
            line-height: 44px;
            color: #572148;
        }

        .blog-container .blog-card .blog-view-sub-title {
            font-weight: 600;
            font-size: 22px;
            line-height: 30px;
            color: #572148;
        }
        .btn-blog {
            background: #572148 !important;
            border-radius: 4px !important;
            padding: 5px 20px !important;
            color: #fff !important;
            font-size: 14px !important;
            display: inline-block !important;
        }
        .btn-blog:hover {
            color: black !important;
            background: #fb5850 !important;
        }
        /* Big Screen */
        @media only screen and (min-width: 1256px){
            .card-height{
                height: 200px;
            }
            
        }

        /* SM screens */
        @media only screen and (min-width: 480px) and (max-width: 768px) {
            .blog-container .blog-title {
                font-size: 30px;
            }

            .blog-container .blog-sub-title {
                font-size: 16px;
            }

            .blog-container .blog-card .blog-view-title {
                font-size: 22px;
                line-height: 26px;
            }

            .blog-container .blog-card .blog-view-sub-title {
                font-size: 16px;
                line-height: 24px;
            }
        }

        /* XS screens */
        @media only screen and (max-width: 480px) {
            .blog-container .blog-title {
                font-size: 30px;
            }

            .blog-container .blog-sub-title {
                font-size: 16px;
            }

            .blog-container .blog-card .blog-view-title {
                font-size: 22px;
                line-height: 26px;
            }

            .blog-container .blog-card .blog-view-sub-title {
                font-size: 16px;
                line-height: 24px;
            }
        }
    `}</style>
        </>
    )
}

export default HomeBlogs